import Auth from "@aws-amplify/auth";
import ENDPOINTS from "./endpoints";

const getAccessJwtToken = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const session = await Auth.currentSession();
      const jwtToken = session.getAccessToken().getJwtToken();
      sessionStorage.setItem("jwt", jwtToken);
      ENDPOINTS.updateRequestHeader(jwtToken);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getAuthenticatedUserEmail = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      let response = {
        user: user["username"],
        email: user?.attributes["email"],
      };
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const get = async uri => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri, {
        headers: ENDPOINTS.getRequestHeader(),
      });
      const body = await response.json();
      if (response.status === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await get(uri));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const post = async (uri, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch(uri, {
        method: "POST",
        headers: ENDPOINTS.getRequestHeader(),
        body: JSON.stringify(payload),
      });
      let body = await response.json();
      if (response.status === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await post(uri, payload));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(body);
      }
    } catch (error) {
      reject(error);
    }
  });
};
