import CellMapView from "../views/CellMapView";
import TechniciansList from "../views/TechniciansList";
import ResultsList from "../views/TestResultsList";
import Tools from "../views/Tools";

import RoomIcon from "@material-ui/icons/Room";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Extension } from "@material-ui/icons";

const ROUTES = [
  // {
  //   label: "Home",
  //   path: "/",
  //   component: Analytics,
  //   icon: HomeIcon,
  // },
  {
    label: "Maps",
    path: "/maps",
    component: CellMapView,
    icon: RoomIcon,
  },
  // {
  //   label: "Technicians",
  //   path: "/technicians",
  //   component: TechniciansList,
  //   icon: EmojiPeopleIcon,
  // },
  // {
  //   label: "Dial Tests",
  //   path: "/reports",
  //   component: ResultsList,
  //   icon: ListAltIcon,
  // },
  // {
  //   label: "Tools",
  //   path: "/tools",
  //   component: Tools,
  //   icon: Extension,
  // }
];

export default ROUTES;
