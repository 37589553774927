export const infoWindowMap = {
  rfCellSites: {
    map: {
      project_location_name: "Site Name",
      longitude: "Latitude",
      latitude: "Longitude",
      city: "City",
      market: "Market",
      state: "State",
      sectors: "Sectors",
    },
    id: "project_location_name",
  },
  psapPolygon: {
    map: {
      psap_id: "PSAP ID",
      psap_fcc_id: "PSAP FCC ID",
      psap_name: "Name",
      psap_telephone: "Phone",
      psap_address: "Address",
      psap_city: "City",
      psap_state: "State",
      psap_zip: "Zip",
      psap_county: "County",
    },
    id: "psap_id",
  },
};

export const siteDetailsModalMap = {
  map: {
    nrcns_site_name: "Site Name",
    nrcns_address: "Address",
    nrcns_zipcode: "Zipcode",
    nrcns_market: "Market",
    nrcns_city: "City",
    nrcns_county: "County",
    nrcns_region: "Region",
    nrcns_lat_lng: "Lat/Long",
  },
  id: "nrcns_id",
  table_map: [
    { width: 50, align: "left", dataKey: "nrcns_cell_id", header: "", sortable: false, type: "checkbox" },
    { width: 160, align: "left", dataKey: "nrcns_cell_id", header: "Cell ID", sortable: true },
    { width: 160, align: "left", dataKey: "nrcns_band_name", header: "Band Name", sortable: true },
    { width: 160, align: "left", dataKey: "nrcns_sector_id", header: "Sector ID", sortable: true },
    { width: 160, align: "left", dataKey: "nrcns_is_test_complete", header: "Test Status", sortable: false },
    { width: 160, align: "left", dataKey: "nrcns_is_n1_upload_complete", header: "Upload Status", sortable: false },
    { width: 160, align: "left", dataKey: "nrcns_assigned_to", header: "Assigned To", sortable: true },
  ],
};
