import React, { useEffect, useState } from 'react';
import { Button, Modal, Uploader } from 'rsuite';
import CardView from '../components/cardview/CardView';
import CsvIcon from './../assets/icons/excel_icon.png';
import { Table, Divider, Notification } from 'rsuite';
import { CSVLink } from 'react-csv';
import ENDPOINTS from "../webservices/endpoints";
import { get, post } from "../webservices/apiservice";
import classes from './Tools.module.css';

const { Column, HeaderCell, Cell } = Table;

const Tools = () => {

    const postBulkUploadTechnicianUrl = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.POST_BULK_UPLOAD_TECHNICIANS;

    const [showModal, setShowModal] = useState(false);
    const [upLoadingFileData, setUpLoadingFileData] = useState([]);
    const [showUpLoadingData, setShowUpLoadingData] = useState(false);
    const [upLoaderHeading, setUpLoaderHeading] = useState("Upload File");
    const [csvReportData, setCsvReportData] = useState({});

    let csvReport = {};
    let bulkUploadTechData = [];

    const getRemoteData = async () => {
			const PATH = "/celltech/dashboard/technician/templateheader";
			const ENDPOINT = "https://r7hlyzm0v1.execute-api.us-west-2.amazonaws.com/v1";

			try {
				let response = await get(`${ENDPOINT}${PATH}`);
				let CSV_HEADER = [];
				for (let index in response.items) {
					CSV_HEADER.push(response.items[index]);
				}
				csvReport.filename = "technician_bulk_upload_template.csv";
				csvReport.headers = CSV_HEADER;
				setCsvReportData(csvReport)
			} catch (err) {
				console.error("Error");
			}
        // fetch("https://r7hlyzm0v1.execute-api.us-west-2.amazonaws.com/v1/celltech/dashboard/technician/templateheader", {
        //     headers: ENDPOINTS.getRequestHeader()
        // })
        //     .then(response => response.json())
        //     .then(header => {
        //         let CSV_HEADER = [];
        //         for (let index in header.items) {
        //             CSV_HEADER.push(header.items[index]);
        //         }
        //         csvReport.filename = "technician_bulk_upload_template.csv";
        //         csvReport.headers = CSV_HEADER;
        //         setCsvReportData(csvReport)
        //     })
        //     .catch(err => {
                
        //     });
    }

    useEffect(() => {
        getRemoteData();
    }, []);

    const addCSV = (e) => {
        if (e[0] !== null && e[0] !== undefined) {
            if (e[0].hasOwnProperty("blobFile")) {
                e[0].blobFile.arrayBuffer().then((res) => {
                    const decoder = new TextDecoder("utf-8");
                    const csv = decoder.decode(res);
                    setUpLoadingFileData(csv.split('\r\n'));
                    setShowUpLoadingData(true);
                });
            }
        }
    };

    function handleOnClickUpload(evtType) {
        if (evtType === "upload") {
            setShowModal(true)
        }
    }

    function handleOnClickDownload() {
        console.log("Click Event");
    }

    const onClickCSVDownLoad = () => {
        var pom = document.createElement('a');
        var csvContent = csvReportData.headers; //here we load our csv data 
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', csvReportData.filename);
        pom.click();
    }

    const handleClose = () => {
        if (upLoadingFileData.length > 0) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
        setUpLoadingFileData([]);
        setUpLoaderHeading("Upload File");
    }

    const handleBulkUpload = async () => {
        let technicans = { technicians: bulkUploadTechData };
        try {
          let response = await post(postBulkUploadTechnicianUrl, technicans);
          if (response.status == "200") {
            setShowModal(false);
            setUpLoadingFileData([]);
            Notification["success"]({
              title: `Success`,
              description: `Technicians is added successfully.`,
            });
          } else {
            alert("Error Please try again");
          }
        } catch (err) {
          console.error(err);
          Notification["error"]({
            title: `Error`,
            description: `An error occured.`,
          });
        }
      };


    const UpLoadModal = () => {
        let uploader = <Uploader draggable
            autoUpload={false}
            autoUpload={false}
            multiple={false}
            disabledFileItem={false}
            accept=".csv" onChange={addCSV} >
            <div className="uploaderContent">
                <img src={CsvIcon} />
                <div className="uploaderDesc1">Drag and drop your CSV here</div>
                <small className="uploaderDesc2">Or select it from your computer</small>
            </div>
        </Uploader>

        if (showUpLoadingData) {
            let arr = upLoadingFileData;
            if (arr.length > 0) {
                setUpLoaderHeading("Import Review");
                bulkUploadTechData = [];
                var headers = arr[0].split(',');
                for (var i = 1; i < arr.length; i++) {
                    var data = arr[i].split(',');
                    var obj = {};
                    for (var j = 0; j < data.length; j++) {
                        obj[headers[j].trim()] = data[j].trim();
                    }
                    if (obj.tech_email != "") {
                        bulkUploadTechData.push(obj);
                    }
                }
                JSON.stringify(bulkUploadTechData);
                uploader = <Table
                    virtualized
                    height={400}
                    data={bulkUploadTechData}
                    onRowClick={data => {
                        console.log(data);
                    }}
                >
                    <Column style={{ borderTopStyle: 'solid', borderTopWidth: 0.5, borderTopColor: '#DFE1EA', padding: 4, backgroundColor: '#FAFAFA', color: '#9397AA', fontFamily: 'Lato', fontSize: 14 }} width={250} align="left" fixed>
                        <HeaderCell>Email</HeaderCell>
                        <Cell className="tableCell" dataKey="tech_email" />
                    </Column>

                    <Column style={{ borderTopStyle: 'solid', borderTopWidth: 0.5, borderTopColor: '#DFE1EA', padding: 4, backgroundColor: '#FAFAFA', color: '#9397AA', fontFamily: 'Lato', fontSize: 14 }} width={150} align="left" >
                        <HeaderCell>Dial Number</HeaderCell>
                        <Cell className="tableCell" dataKey="tech_dial_number" />
                    </Column>

                    <Column style={{ borderTopStyle: 'solid', borderTopWidth: 0.5, borderTopColor: '#DFE1EA', padding: 4, backgroundColor: '#FAFAFA', color: '#9397AA', fontFamily: 'Lato', fontSize: 14 }} width={150} align="left" >
                        <HeaderCell>Authorise</HeaderCell>
                        <Cell className="tableCell" dataKey="tech_authorised" />
                    </Column>

                    <Column style={{ borderTopStyle: 'solid', borderTopWidth: 0.5, borderTopColor: '#DFE1EA', padding: 4, backgroundColor: '#FAFAFA', color: '#9397AA', fontFamily: 'Lato', fontSize: 14 }} width={220} align="left" >
                        <HeaderCell>Site Name</HeaderCell>
                        <Cell className="tableCell" dataKey="tech_site_name" />
                    </Column>
                </Table>
            }
        }

        return (
            <Modal size="md" backdrop="static" show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="upLoaderHeading">Bulk Upload Technicians</Modal.Title>
                </Modal.Header>
                <div className="upLoaderSubHeading">
                    {upLoaderHeading}
                </div>
                <Modal.Body>
                    <div className={classes.modalBody}>
                        {uploader}
                    </div>
                </Modal.Body>
                <Divider></Divider>
                <Modal.Footer>
                    <Button className="cancelButton" style={{ marginTop: 16 }} onClick={handleClose} appearance="primary">
                        Cancel
                    </Button>
                    {upLoadingFileData.length > 0 ? <Button className="saveButton" style={{ marginTop: 16 }} onClick={handleBulkUpload} appearance="primary">
                        Import
                    </Button> : null}

                </Modal.Footer>
            </Modal >
        )
    }

    return (
        <div>
            <div className="CustomPageHeader">
                <label>Tools</label>
            </div>
            {csvReportData ? <div>
                <UpLoadModal />
                <CardView heading="Bulk Upload Technicians" icon={CsvIcon} description1="Register multiple technicans at once" csvReport={csvReportData} description2="Download the CVS template file and upload back with data" buttonText="Upload" showDownload={true} onclickDownload={onClickCSVDownLoad} onclick={() => handleOnClickUpload("upload")}></CardView>
                <CardView heading="Upload PSAP Boundary geoJSON" description1="Add multiple PSAP Boundary Information" description2="geoJSON file can be obtained from Google Earth" buttonText="Upload" onclick={() => handleOnClickUpload("geojson")}></CardView>
                <CardView heading="Download E911 Field Test Reports" description1="Filter reports based on multiple parameters" description2="Download bulk test data into CSV or JSON format" buttonText="Download" onclick={handleOnClickDownload}></CardView>
            </div> : null}
        </div>

    );
}

export default Tools;
