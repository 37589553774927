import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';

import { IconButton, Icon, Table, Modal, Button, Grid, Row, Col, Divider, Radio, RadioGroup } from 'rsuite';
import download from '../assets/icons/download.png';
import { TEST_RESULTS_TABLE } from '../config/TableConfig';
import { get } from "../webservices/apiservice";

const { Column, HeaderCell, Cell, Pagination } = Table;
const TABLE_ROWS_SIZES = [{ value: 25, label: 25 }, { value: 50, label: 50 }, { value: 100, label: 100 }]

let CSV_HEADER = [];
let csvReport = {};
let selectedTestReportData = "";

const MODAL_CONFIG = [
  {
    header: 'General',
    keyValues: [
      { 'Test ID': 'field_test_report_id' },
      { 'Field Test Status': 'mobile_test_status' },
      { 'Distance (mtr)': 'mobile_test_distance' },
      { 'Dial Number': 'mobile_tech_dial_number' },
      { 'Date & Time': 'mobile_test_date_time' }
    ]
  },
  {
    header: 'Intrado',
    keyValues: [
      { 'PSAP ID': 'mobile_sms_psap_id' },
      { 'Nr Cell ID': 'mobile_sms_nrcell_id' },
      { 'Lat / Long': 'intrado.latlong' },
      { 'ESRK': 'mobile_sms_esrk' },
      { 'Horizontal Uncertainty': 'mobile_sms_uncertainty_h' },
      { 'Confidence': 'mobile_sms_confidence' }
    ]
  },
  {
    header: 'NexsysOne',
    keyValues: [
      { 'Upload Status': 'nexsysone.uploadstatus' },
      { 'Site Name': 'nexsysone.sitename' },
      { 'Site Doc Folder': 'nexsysone.docfolder' }
    ]
  },
  {
    header: 'Technician / Device',
    keyValues: [
      { 'Email': 'mobile_tech_email' },
      { 'Location': 'technician.location' },
      { 'Device Cell ID': 'technician.cellid' },
      { 'RSRP (CSI / SS)': 'technician.rsrp' },
      { 'RSRQ (CSI / SS)': 'technician.rsrq' },
      { 'SINR (CSI / SS)': 'technician.sinr' }
    ]
  }
];

const ResultsList = () => {

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [testResultsData, setTestResultsData] = useState([]);
  const [pageDataItems, setPageDataItems] = useState([]);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(TABLE_ROWS_SIZES[1]["value"]);

  const [tableSortColumn, setTableSortColumn] = useState("Date & Time");
  const [columnSortType, setColumnSortType] = useState("desc");

  const [show, setShowModal] = useState(false);
  const [testDetails, setTestDetails] = useState([]);
  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
  const [reportDownLoadingFormat, setReportDownLoadingFormat] = useState("csv");

  const handleClose = () => {
    setShowModal(false);
    setShowDownloadReportModal(false);
    setReportDownLoadingFormat("csv");
  }

  const assignRemoteData = (items) => {
    setTestResultsData(items);
    setIsPageLoading(false);

    let pageItems = getPageDataItems(items);
    setPageDataItems(pageItems);
  }

  const getRemoteData = async () => {
    const PATH = "/celltech/dashboard/testresults/getlist";
    const ENDPOINT = "https://r7hlyzm0v1.execute-api.us-west-2.amazonaws.com/v1";

      try {
        let response = await get(`${ENDPOINT}${PATH}`);
        let items = response["items"];
        items.map((item, index) => {
          item["general.datetime"] = item["mobile_test_date_time"] !== undefined ? (item["mobile_test_date_time"]).substr(0, 16) : "-";
          item["technician.cellid"] = (item["mobile_telephony_nrcell_id"]).padStart(10, "0");
          item["intrado.psapid"] = item["mobile_sms_psap_id"];
          item["intrado.cellid"] = item["mobile_sms_nrcell_id"];
          item["intrado.latlong"] = item["mobile_sms_latitude"] + " / " + item["mobile_sms_longitude"];
          item["technician.rsrp"] = item["mobile_telephony_csi_rsrp"] + " / " + item["mobile_telephony_ss_rsrp"];
          item["technician.rsrq"] = item["mobile_telephony_csi_rsrq"] + " / " + item["mobile_telephony_ss_rsrq"];
          item["technician.sinr"] = item["mobile_telephony_csi_sinr"] + " / " + item["mobile_telephony_ss_sinr"];
          item["nexsysone.sitename"] = item["report_n1_site_name"] !== undefined ? item["report_n1_site_name"] : "-- LALAS00001A --";
          item["nexsysone.uploadstatus"] = item["report_n1_upload_status"] !== undefined ? item["report_n1_upload_status"] : "-- Test Only --";
          item["nexsysone.docfolder"] = `${item["report_n1_site_docfolder_id"]} / ${item["report_n1_site_docfolder_name"]}`;
          item["technician.location"] = item["mobile_device_latitude"] + " / " + item["mobile_device_longitude"]
          item["technician.cellid"] = item["mobile_telephony_nrcell_id"]
          return item
        });
        assignRemoteData(items);
      } catch (error) {
        assignRemoteData([]);
      }
  }

  const handleAction = (evt, actionType = "View") => {
    selectedTestReportData = evt;
    if (actionType === "View") {
      setTestDetails(evt);
      setShowModal(!show);
    } else if (actionType === "Download") {
      let data = [];
      data.push(evt);
      for (let name in evt) {
        if (!name.includes(".")) {
          let tempObj = {
            label: name,
            key: name
          }
          CSV_HEADER.push(tempObj);
        }
      }
      csvReport.filename = evt["field_test_report_id"] + ".csv";
      csvReport.headers = CSV_HEADER;
      csvReport.data = data;
      setShowDownloadReportModal(true)
    }
  }

  const retryNexsysOneUpload = () => {
    window.alert("RETRY");
  }

  const openLocation = (obj) => {
    let location = obj.split(" / ");
    window.open("https://maps.google.com?q=" + location[0] + "," + location[1])
  }

  function handleDownloadReport() {
    setShowDownloadReportModal(false);
    let data = JSON.stringify(selectedTestReportData);
    let fileName = selectedTestReportData["field_test_report_id"] + '.json';
    var fileToSave = new Blob([data], {
      type: 'application/json'
    });
    saveAs(fileToSave, fileName);
    setReportDownLoadingFormat("csv");
  }

  function handleReportingFormatChange(format) {
    setReportDownLoadingFormat(format)
  }

  const DownloadModal = () => {

    let downLoadButton = <CSVLink className="saveButton" {...csvReport}><Button appearance="default" appearance="primary" onClick={handleClose} className="saveButton">Download</Button></CSVLink>

    if (reportDownLoadingFormat === "json") {
      downLoadButton = <Button appearance="default" appearance="primary" className="saveButton" onClick={handleDownloadReport}>Download</Button>
    }

    return (
      <Modal size='sm' show={showDownloadReportModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title ></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <div className="downloadImage">
            <img src={download} alt="" />
          </div>
          <div className="modalsubHeadingBlock">
            <p className="DownloadModalTitle">Download the field test report</p>
            <p className="modalSubHeading">Select the file format</p>
            <RadioGroup name="radioList" inline onChange={handleReportingFormatChange} value={reportDownLoadingFormat}>
              <Radio name="test" className="radioOption" value="csv">CSV</Radio>
              <Radio name="test" className="radioOption" value="json">JSON</Radio>
            </RadioGroup>
          </div>
        </Modal.Body >
        <hr className="divider" />
        <Modal.Footer>
          <Button appearance="default" className="cancelButton" onClick={handleClose}>Cancel</Button>
          {downLoadButton}
        </Modal.Footer>
      </Modal >
    )
  };

  const ModalContent = () => {
    const HEADERS = Object.keys(MODAL_CONFIG);

    return (
      <Modal size='md' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="ModalTitle">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            {
              MODAL_CONFIG.map((item, index) => {
                let kv = item["keyValues"];
                return (
                  <>
                    <Row className="TestDetailsCatogory">
                      <Divider>{item["header"]}</Divider>
                      <Col md={7}>
                        {/* <Divider>{item["header"]}</Divider> */}
                        {/* <label>{item["header"]}</label> */}
                      </Col>
                    </Row>
                    {
                      kv.map((innerItem, idx) => {
                        let rowName = (Object.keys(innerItem))[0];
                        let rowValue = testDetails[innerItem[rowName]];
                        let retry = false;
                        let isLocation = false;
                        if (rowName === "Upload Status" && rowValue !== "Success") {
                          retry = true;
                        }
                        if (rowName === "Lat / Long" & rowValue !== " / ") {
                          isLocation = true;
                        }

                        return (
                          <Row className='TestDetailsContent'>
                            <Col md={5}>
                              <label className="ModalColumnLabel">{rowName}</label>
                            </Col>
                            <Col md={10} className={`${rowValue} LabelVal`}>{rowValue}
                              {retry === true ?
                                <span onClick={retryNexsysOneUpload} className="RetryUpload">Retry</span>
                                :
                                isLocation === true ?
                                  <Icon className="MapLocation" icon="map-marker" size='lg ' onClick={() => openLocation(rowValue)} />
                                  :
                                  ""
                              }
                            </Col>
                          </Row>
                        )
                      })
                    }
                    <div className="TestDetailsDivider" />
                  </>
                )
              })
            }
          </Grid>
        </Modal.Body >
        <Modal.Footer>
          <Button className="CloseButton" appearance="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    )
  }

  const getSortedData = () => {
    let sortColumn = tableSortColumn;
    let sortType = columnSortType;

    if (sortColumn && sortType) {
      pageDataItems.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        let retval = -999;
        let isSame = ["cellid", "datetime"].includes(sortColumn);

        if (typeof x === 'string' && isSame === false) {
          x = x.charCodeAt();
        }
        if (typeof y === 'string' && isSame === false) {
          y = y.charCodeAt();
        }
        if (sortType === 'asc' && isSame === false) {
          retval = x - y;
        } else if (isSame === false) {
          retval = y - x;
        }

        if (retval === -999) {
          retval = (sortType === 'asc') ? (a[sortColumn] <= b[sortColumn]) ? -1 : 1 : (a[sortColumn] >= b[sortColumn]) ? -1 : 1;
        }

        return retval
      });
      return pageDataItems;
    }

    return pageDataItems;
  }

  const getPageDataItems = (sourceData) => {
    let items = (sourceData === undefined) ? testResultsData : sourceData;
    let newData = items.filter((v, i) => {
      const start = itemsPerPage * (activePageNumber - 1);
      const end = start + itemsPerPage;
      return i >= start && i < end;
    });
    return newData;
  }


  const handleColumnSort = (column, sortType) => {
    setTimeout(() => {
      setTableSortColumn(column);
      setColumnSortType(sortType);
    }, 500);
  }

  const handlePageChange = (page) => {
    setActivePageNumber(page);
  }

  const handleLengthChange = (numOfRows) => {
    setActivePageNumber(1);
    setItemsPerPage(numOfRows);
  }

  useEffect(() => {
    let pageItems = getPageDataItems();
    setPageDataItems(pageItems);
  }, [activePageNumber, itemsPerPage]);

  useEffect(() => {
    getRemoteData();
  }, []);

  return (
    <div className="TableView">
      <div className="CustomPageHeader">
        <label>Dial Tests</label>
      </div>
      <div style={{ marginLeft: '24px', marginRight: '24px', marginTop: '16px' }}>
        <>
          <Table height={500}
            data={getSortedData()}
            sortColumn={tableSortColumn}
            sortType={columnSortType}
            onSortColumn={handleColumnSort}
            loading={isPageLoading}>
            {TEST_RESULTS_TABLE.map((item, index) => {
              return (
                <Column width={item["width"]} align={item["align"]} sortable={item["sortable"]}>
                  <HeaderCell>{item["header"]}</HeaderCell>
                  {item["header"] === "Action" ?
                    <Cell>
                      {
                        rowData => {
                          function handleOnClickView() {
                            handleAction(rowData, "View");
                          }

                          function handleOnClickDownload() {
                            handleAction(rowData, "Download");
                          }
                          return (
                            <span>
                              <IconButton appearance="subtle" icon={<Icon icon="eye" />} onClick={handleOnClickView} />
                              <IconButton appearance="subtle" icon={<Icon icon="download" />} onClick={handleOnClickDownload} />
                            </span>
                          )
                        }
                      }
                    </Cell>
                    :
                    <Cell className="TableViewCustomCellValue" dataKey={item["dataKey"]} />
                  }
                </Column>
              );
            })}
          </Table>
          {show ? <ModalContent /> : null}
          {showDownloadReportModal ? <DownloadModal /> : null}
          <Pagination
            lengthMenu={TABLE_ROWS_SIZES}
            activePage={activePageNumber}
            displayLength={itemsPerPage}
            total={testResultsData.length}
            onChangePage={handlePageChange}
            onChangeLength={handleLengthChange} />

        </>
      </div>
    </div>
  )
}

export default ResultsList;