let REDIRECTS = {
  np: "https://celltech-portal-nonprod-oss.dish.com/",
  prod: "https://dobyoo0cdzu8g.cloudfront.net/",
  local: `http://localhost:${process.env.PORT ? process.env.PORT : 6075}/`,
};

//const redirectURL = process.env.NODE_ENV === 'development' ? ('http://localhost:6075/') : process.env.REACT_APP_STAGE ? REDIRECTS[process.env.REACT_APP_STAGE] : REDIRECTS['np'];

const redirectURL = REDIRECTS[process.env.REACT_APP_STAGE];
console.log("Redirect URL = ", redirectURL);

let AWSCONFIG = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id: "us-west-2:e367d108-cc6b-4116-96b2-69bbf4de3b93",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_VuxNDtEYx",
  aws_user_pools_web_client_id: "71bk116r8csnbm06m1fndak650",
  Auth: {
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: "dishoss-tech-np.auth.us-west-2.amazoncognito.com",
      scope: ["phone", "email", "openid", "aws.cognito.signin.user.admin", "profile", "https://oss-customscope.com/celltech.admin"],
      redirectSignIn: redirectURL,
      redirectSignOut: redirectURL,
      responseType: "code",
    },
  },
};

export default AWSCONFIG;
