import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";

import Sidebar from "./components/Sidebar";
import ROUTES from "./config/routes";
import Login from "./views/Login";

import Amplify from "aws-amplify";
import AWSCONFIG from "./config/awsexport";

Amplify.configure(AWSCONFIG);

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} component={Login} key={`login-route`} />
        </Switch>
        <div className="Sidebar">
          <div className="SidebarContainer">
            <Sidebar />
          </div>
        </div>
        <div className="MainContentBody">
          <Switch>
            {ROUTES.map((route, index) => {
              return <Route exact path={route.path} component={route.component} key={`route-${index}`} />;
            })}
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
