import { makeStyles } from "@material-ui/core";

export const commonStyles = makeStyles(theme => ({
  buttonPrimary: {
    width: "max-content",
    height: "fit-content",
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 35,
    paddingRight: 35,
    margin: 5,
    backgroundColor: "#3489EC",
    color: "#fff",
    // border: `1px solid ${"#3489EC"}`,
    borderRadius: 3,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#3489EC",
      color: "#fff",
      opacity: 0.8,
    },
  },
  buttonSecondary: {
    width: "max-content",
    height: "fit-content",
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 600,
    padding: "7px 34px",
    margin: 5,
    border: `1px solid #3489EC`,
    borderRadius: 3,
    backgroundColor: "transparent",
    color: "#3489EC",
    transition: "0.2s ease-in-out",
    "&:hover, focus": {
      backgroundColor: "white",
      color: "#3489EC",
      opacity: 0.8,
    },
  },
  input: {
    width: "99%",
    marginTop: 5,
    marginLeft: 5,
    borderRadius: 3,
    backgroundColor: "#F1F2F3",
    boxShadow: "0 0 0 1px transparent",
    fontFamily: "Lato",
    border: "1px solid transparent",
    padding: 10,
    fontSize: 14,
    fontWeight: 500,
    "&:hover, &:focus": {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "0 0 0 1px white",
    },
  },
  label: {
    color: "#545964",
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    margin: 5,
  },
  searchBarInput: {
    fontFamily: "Lato",
    fontSize: 16,
    height: "fit-content",
    borderRadius: 2,
    border: `1px solid #fff`,
    "&:hover, &:focus": {
      border: `1px solid #3489ec`,
      backgroundColor: "#fff",
    },
    searchBarIcon: {
      "&:hover, &:focus": {
        color: "#3489ec",
        cursor: "pointer",
      },
    },
  },
  dropdown: {
    marginTop: 5,
    width: "100%",
    "& a": {
      width: "100%",
    },
    "& ul": {
      width: "100%",
    },
  },
}));
