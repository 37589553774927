import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Button, Grid, Row, Col, Notification, InputPicker, Loader as RLoader } from "rsuite";
import ENDPOINTS from "../webservices/endpoints";
import { get, getAuthenticatedUserEmail } from "../webservices/apiservice";
import { Loader } from "@googlemaps/js-api-loader";
import dishLogo from "../assets/icons/dish_logo_1000.png";
import { infoWindowMap } from "../config/mapsConfig";
import SiteDetailsModal from "./modals/site_details";

const useStyles = makeStyles(theme => ({
  dropdown: {
    background: "#fff",
    borderRadius: 4,
    fontFamily: "Lato",
    fontWeight: 500,
    marginTop: 5,
    width: "100%",
    "& a": {
      width: "100%",
    },
    "& ul": {
      width: "100%",
    },
  },
  button: {
    backgroundColor: "#3489EC",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#FFFFFF",
    minWidth: "fit-content",
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#3489EC",
      opacity: 0.9,
      color: "#FFFFFF",
    },
  },
}));

const CellMapView = () => {
  //constants
  const classes = useStyles();
  const getPsapStatesUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_PSAP_STATES;
  const getPsapCitiesUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_PSAP_CITIES;
  const getPsapBoundaryUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_PSAP_BOUNDARY;
  const getRfRegionsUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_REGIONS;
  const getRfMarketsUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_MARKETS;
  const getRfCitiesUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_CITIES;
  const getRfSitesUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_SITES;
  const techsOnlineUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_TECHS_ONLINE;
  const wsTechsOnline = ENDPOINTS.MAPS.WS_TECHS_ONLINE;
  const getRoleUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.ROLE_MANAGEMENT;
  const getRfAois = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_AOIS;

  const TECHS_ONLINE_REFRESH_DURATION = 10 * 1000;

  const rfCellSitesInfoWindowMap = infoWindowMap.rfCellSites;
  const psapBoundaryPolygonInfoWindowMap = infoWindowMap.psapPolygon;

  const webSocketRef = useRef(null);

  const loader = new Loader({
    apiKey: "AIzaSyBhGmqaI67bKamFm1A9AZ9I8sTKEtB6x9k",
    version: "weekly",
  });
  const dishNetworksCOLocation = { lat: 39.54598, lng: -104.856233 };

  //useStates
  const [psapStates, setPsapStates] = useState({
    data: [],
    active: {},
    isLoading: false,
  });
  const [psapCities, setPsapCities] = useState({
    data: [],
    active: {},
    isLoading: false,
  });
  const [psapBoundaries, setPsapBoundaries] = useState([]);
  const [psapPolygonCoordinates, setPsapPolygonCoordinates] = useState([]);
  // const [polygonCenterCoordinates, setPolygonCenterCoordinates] = useState({});
  const [initialRoleFetchLoading, setInitialRoleFetchLoading] = useState(false);

  const [rfRegions, setRfRegions] = useState({
    data: [],
    active: "",
    isLoading: true,
  });
  const [rfMarkets, setRfMarkets] = useState({
    data: [],
    active: "",
    isLoading: true,
  });
  const [rfCities, setRfCities] = useState({
    data: [],
    active: "",
    isLoading: false,
  });
  const [aois, setAois] = useState([]);
  const [rfSites, setRfSites] = useState([]);
  const [isBtnLoading, setBtnLoading] = useState({
    psapBtn: false,
    rfSiteBtn: false,
  });

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const [latestSubmit, setLatestSubmit] = useState("null");
  const [isMapLoaded, setMapLoaded] = useState(false);

  // const [bounds, setBounds] = useState(null);
  const [gmap, setGMap] = useState(null);
  const [infowindow, setInfowindow] = useState(null);

  const [polygons, setPolygons] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [liveTrackTechnicians, setLiveTrackTechnicians] = useState(true);
  const [mapLiveTechnicians, setMapLiveTechnicians] = useState([]);
  const [liveTechnicians, setLiveTechnicians] = useState([]);

  /*FUNCTIONS*/

  //api calls

  const init = async () => {
    try {
      await initMap();
      await initRoleBasedRfDetails();
      await getStates();
      // await getRfRegions();
      // await getTechsOnline();
    } catch (err) {
      console.error(err);
    }
  };

  const initRoleBasedRfDetails = async () => {
    try {
      setInitialRoleFetchLoading(true);
      let userEmail = await getCognitoUserEmail();
      let {
        region,
        market: { name },
      } = await getRole(userEmail);
      let aoiData = await getAois(region, name);
      let allAois = await getAllAois(name);
      setRfCities({
        data: [{ id: "-1", name: "All" }, ...aoiData.aoi],
        active: { id: "-1", name: "All" },
        isLoading: false,
      });
      let filteredAois = allAois.items.filter(item => item.lat !== "" && item.lng !== "" && !item.name.includes("TEST"));
      setAois(filteredAois);
      setRfRegions({
        data: [],
        active: { name: aoiData.region },
        isLoading: true,
      });
      setRfMarkets({
        data: [],
        active: aoiData.market,
        isLoading: true,
      });
      // await getRfSitesFromAoi(aoiData.market.id, "-1");
      await onSubmitRf(undefined, filteredAois, aoiData.market.id, "-1");
      setInitialRoleFetchLoading(false);
    } catch (err) {
      console.log(err);
      setInitialRoleFetchLoading(false);
      Notification["error"]({
        title: "Error",
        description: "Error loading role based information. Try reloading the page.",
      });
    }
  };

  const getCognitoUserEmail = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await getAuthenticatedUserEmail();
        resolve(response?.email);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getRole = async email => {
    return new Promise(async (resolve, reject) => {
      try {
        let query = `${getRoleUrl}?email=${email}`;
        console.log(query);
        let response = await get(query);
        resolve(response?.items[0]);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getAllAois = async market => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = `${getRfSitesUrl}?market=${market}`;
        let response = await get(queryString);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getAois = async (region, market) => {
    return new Promise(async (resolve, reject) => {
      try {
        let query = `${getRfAois}?region=${region}&market=${market}`;
        let response = await get(query);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getStates = async () => {
    try {
      setPsapStates(prev => ({ ...prev, isLoading: true }));
      let response = await get(getPsapStatesUrl);
      if (response["status"] === 200) {
        setPsapStates(prev => ({ ...prev, data: response["items"].filter(item => item["psap_state_name"] !== ""), active: {}, isLoading: false }));
      }
    } catch (error) {
      console.error(error);
      Notification["error"]({
        title: "Error",
        description: "Couldn't fetch the PSAP States. Try reloading the page.",
      });
    }
  };

  const getCities = async state => {
    try {
      setPsapCities(prev => ({ ...prev, isLoading: true }));
      let queryUrl = `${getPsapCitiesUrl}?state=${state}`;
      let response = await get(queryUrl);
      if (response["status"] === 200) {
        setPsapCities(prev => ({ ...prev, data: response["items"], active: {}, isLoading: false }));
      }
    } catch (error) {
      console.error(error);
      Notification["error"]({
        title: "Error",
        description: "Couldn't fetch the PSAP Cities. Try reloading the page.",
      });
    }
  };

  const getBoundary = async (rfCity = null) => {
    !rfCity && setLatestSubmit("psap");
    try {
      let stateCode = psapStates.active["psap_state"];
      let queryUrl = rfCity
        ? `${getPsapBoundaryUrl}?psap_city=${rfCity.nrcns_city}`
        : `${getPsapBoundaryUrl}?psap_state=${stateCode}&psap_city=${psapCities.active["psap_city"]}`;
      !rfCity && setBtnLoading(prev => ({ ...prev, psapBtn: true }));
      let response = await get(queryUrl);
      if (Array.isArray(response.items)) {
        if (response.items.length === 0) {
          Notification["error"]({
            title: "Error",
            description: `PSAP Not found for the city - ${rfCity.nrcns_city}`,
          });
        } else {
          setPsapBoundaries(response.items.map(item => ({ ...item, psap_polygon_coordinates: getPolygonCoordinates(item["psap_polygon_coordinates"]) })));
          setPsapPolygonCoordinates(getPolygonCoordinates(response["items"][0]["psap_polygon_coordinates"]));
        }
      }
      !rfCity && setBtnLoading(prev => ({ ...prev, psapBtn: false }));
    } catch (error) {
      console.error("GET-Boundary ERROR", error);
      Notification["error"]({
        title: "Error",
        description: "Couldn't fetch the PSAP Boundaries. Try reloading the page.",
      });
      setBtnLoading(prev => ({ ...prev, psapBtn: false }));
    }
  };

  // const getRfRegions = async () => {
  //   try {
  //     setRfRegions(prev => ({ ...prev, isLoading: true }));
  //     let response = await get(getRfRegionsUrl);
  //     setRfRegions(prev => ({ ...prev, data: response.items.map(item => item["nrcns_region"]), isLoading: false }));
  //   } catch (err) {
  //     console.error(err);
  //     Notification["error"]({
  //       title: "Error",
  //       description: "Couldn't fetch the Dish Site Regions. Try reloading the page.",
  //     });
  //   }
  // };

  // const getRfMarkets = async region => {
  //   try {
  //     setRfMarkets(prev => ({ ...prev, isLoading: true }));
  //     let queryString = `${getRfMarketsUrl}?region=${region}`;
  //     let response = await get(queryString);
  //     setRfMarkets(prev => ({ ...prev, data: getUniqueMarkets(response.items), isLoading: false }));
  //   } catch (err) {
  //     console.error(err);
  //     Notification["error"]({
  //       title: "Error",
  //       description: "Couldn't fetch the Dish Sites's Markets. Try reloading the page.",
  //     });
  //   }
  // };

  // const getRfCities = async market => {
  //   try {
  //     setRfCities(prev => ({ ...prev, isLoading: true }));
  //     let queryString = `${getRfCitiesUrl}?market=${market}`;
  //     let response = await get(queryString);
  //     setRfCities(prev => ({ ...prev, data: response.items.map(item => ({ nrcns_city: item })), isLoading: false }));
  //   } catch (err) {
  //     console.error(err);
  //     Notification["error"]({
  //       title: "Error",
  //       description: "Couldn't fetch the Dish Sites's Markets. Try reloading the page.",
  //     });
  //   }
  // };

  // const getRfSites = async () => {
  //   let items = [];
  //   const fetchRecurs = async (url, curr = 0) => {
  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         let response = await get(`${url}&page=${curr}`);
  //         items.push(...response["items"]);
  //         if (parseInt(response["currentPage"]) < parseInt(response["pages"])) {
  //           resolve(await fetchRecurs(url, curr + 1));
  //         } else {
  //           resolve();
  //         }
  //       } catch (err) {
  //         reject(err);
  //       }
  //     });
  //   };

  //   try {
  //     let queryString = `${getRfSitesUrl}?market=${rfMarkets.active["nrcns_market"]}&city=${rfCities.active["nrcns_city"]}`;
  //     setBtnLoading(prev => ({ ...prev, rfSiteBtn: true }));
  //     await fetchRecurs(queryString);
  //     await getBoundary(rfCities.active);
  //     setRfSites(modifyRfSitesResponse(items, "project_location_name"));
  //     setBtnLoading(prev => ({ ...prev, rfSiteBtn: false }));
  //   } catch (err) {
  //     console.error(err);
  //     Notification["error"]({
  //       title: "Error",
  //       description: "Couldn't fetch the Dish Cell Site. Try reloading the page.",
  //     });
  //     setBtnLoading(prev => ({ ...prev, rfSiteBtn: false }));
  //   }
  // };

  const getRfSitesFromAoi = (stateCode, cityCode, allAois = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (stateCode && stateCode !== "") {
          let newAllAois = allAois ? allAois : aois;
          if (cityCode === "-1") {
            setRfSites(newAllAois.filter(aoi => aoi.name.includes(stateCode)));
          } else {
            let str = `${stateCode}${cityCode}`;
            setRfSites(newAllAois.filter(aoi => aoi.name.includes(str)));
          }
          resolve();
        } else {
          reject("Invalid status code provided to function:getRfSitesFromAoi");
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  // const getTechsOnline = async () => {
  //   if (liveTrackTechnicians) {
  //     try {
  //       // let response = await get(techsOnlineUrl);
  //       let res = generateMockData(25);
  //       liveTechnicians.length === 0
  //         ? setLiveTechnicians(res)
  //         : res.forEach(newItem => {
  //             let index = liveTechnicians.findIndex(oldItem => oldItem.connection_id === newItem.connection_id);
  //             if (index === -1) {
  //               setLiveTechnicians(prev => [...prev, newItem]);
  //             } else {
  //               let newData = [...liveTechnicians];
  //               newData[index].tech_last_shown_time = newItem.tech_last_shown_time;
  //               newData[index].lat = newItem.lat;
  //               newData[index].lng = newItem.lng;
  //               setLiveTechnicians(newData);
  //             }
  //           });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  //input handlers

  const onSelectPsapState = state => {
    setPsapStates(prev => ({ ...prev, active: state }));
    getCities(state["psap_state"]);
  };

  const onSelectPsapCity = city => {
    setPsapCities(prev => ({ ...prev, active: city }));
  };

  const onSubmitPsap = event => {
    event.target.blur();
    getBoundary();
  };

  const onSelectRfRegion = region => {
    setRfRegions(prev => ({ ...prev, active: { name: region } }));
    // getRfMarkets(region);
  };

  const onSelectRfCity = city => {
    setRfCities(prev => ({ ...prev, active: city }));
    onSubmitRf(undefined, null, null, city.id);
  };

  const onSelectRfMarket = market => {
    setRfMarkets(prev => ({ ...prev, active: market }));
    // getRfCities(market["nrcns_market"]);
  };

  const onSubmitRf = async (e, allAois = null, stateCode = null, cityCode = null) => {
    try {
      setLatestSubmit("rf");
      e !== undefined && e.target.blur();
      setBtnLoading(prev => ({ ...prev, rfSiteBtn: true }));
      await getRfSitesFromAoi(stateCode ?? rfMarkets.active.id, cityCode ?? rfCities.active.id, allAois);
      setBtnLoading(prev => ({ ...prev, rfSiteBtn: false }));
    } catch (err) {
      console.log(err);
      setBtnLoading(prev => ({ ...prev, rfSiteBtn: false }));
    }
  };

  //content constructors

  const constructDishInfoWindow = () => {
    let container = document.createElement("div");
    let imgElement = document.createElement("img");
    imgElement.style = "width: 100px; height: 100px;";
    imgElement.src = dishLogo;
    container.appendChild(imgElement);
    return container;
  };

  const constructInfoWindow = (input, keyMap, title, modal = null) => {
    let mainDiv = document.createElement("div");
    mainDiv.style = "padding: 10px;";
    let titleDiv = document.createElement("div");
    titleDiv.style = "font-family: Lato; font-size: 14px; font-weight: 700; margin-bottom: 8px; color: #3489EC; padding: 2px;";
    titleDiv.innerHTML = title;
    mainDiv.appendChild(titleDiv);
    let innerDiv = document.createElement("div");
    let footerDiv = document.createElement("div");
    footerDiv.style = "display: flex; justify-content: flex-end; margin-top: 10px; ";
    let manageAnchorBtn = document.createElement("a");
    manageAnchorBtn.innerHTML = "Manage";
    manageAnchorBtn.style = "font-family: Lato; font-size: 14px; font-weight: 600; cursor: pointer; text-decoration : none; ";
    manageAnchorBtn.onclick = e => {
      e.preventDefault();
      if (modal) {
        modal.viewHandler(true);
        modal.dataHandler({
          [keyMap.id]: input[keyMap.id],
        });
      }
    };
    if (modal) {
      footerDiv.appendChild(manageAnchorBtn);
    }

    const divLabelElement = (key, value) => {
      let divEl = document.createElement("div");
      divEl.style = "padding: 2px;";
      let keyLabelEl = document.createElement("label");
      keyLabelEl.style = "font-family: Lato; font-weight: 400; color: #7F7F7F; font-size: 14px";
      let valLabelEl = document.createElement("label");
      valLabelEl.style = "font-family: Lato; color:#2E384D; font-size: 14px; font-weight: 400;";
      keyLabelEl.innerHTML = key;
      valLabelEl.innerHTML = value;
      divEl.append(keyLabelEl, " : ", valLabelEl);
      return divEl;
    };

    Object.entries(keyMap["map"]).map(([key, value], index) => {
      if (key === "sectors") {
        innerDiv.appendChild(divLabelElement("No. of sectors", input[key].length));
        // innerDiv.appendChild(divLabelElement("No. of sectors Test completed", input[key].filter(el => el["nrcns_is_test_complete"] === true).length));
        // innerDiv.appendChild(divLabelElement("No. of sectors Upload completed", input[key].filter(el => el["nrcns_is_n1_upload_complete"] === true).length));
      } else {
        innerDiv.appendChild(divLabelElement(value, input[key]));
      }
    });
    mainDiv.appendChild(innerDiv);
    // mainDiv.appendChild(footerDiv);
    return mainDiv;
  };

  const initMap = async () => {
    let mapDivEl = document.getElementById("map");
    return new Promise(async (resolve, reject) => {
      try {
        await loader.load();
        if (mapDivEl) {
          setGMap(
            new window.google.maps.Map(mapDivEl, {
              center: psapPolygonCoordinates.length > 0 ? { ...getPolygonCenterCoordinates(psapPolygonCoordinates) } : dishNetworksCOLocation,
              zoom: 16,
              streetViewControl: false,
            })
          );
          // setBounds(new window.google.maps.LatLngBounds());

          setInfowindow(
            new window.google.maps.InfoWindow({
              size: new window.google.maps.Size(100, 50),
            })
          );
        }
        setMapLoaded(true);
        resolve();
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  const setDefaultMarker = (clearMap = true) => {
    let position = new window.google.maps.LatLng(dishNetworksCOLocation.lat, dishNetworksCOLocation.lng);
    let dishLocationMarker = new window.google.maps.Marker({
      position: position,
      title: "Dish",
    });
    clearMap ? dishLocationMarker.setMap(gmap) : dishLocationMarker.setMap(null);

    infowindow.setContent(constructDishInfoWindow());

    dishLocationMarker.addListener("mouseover", () => {
      infowindow.open({
        anchor: dishLocationMarker,
        map: gmap,
        shouldFocus: false,
      });
    });
    dishLocationMarker.addListener("mouseout", () => {
      infowindow.close();
    });
    markers.filter(marker => marker.title === dishLocationMarker.title).length === 0 && setMarkers(prev => [...prev, dishLocationMarker]);
  };

  const clearPolygons = async () => {
    return new Promise((res, rej) => {
      try {
        let i = 0;
        for (i = 0; i < polygons.length; i++) {
          polygons[i].setMap(null);
        }
        if (i === polygons.length) {
          setPolygons([]);
          res("success");
        }
      } catch (error) {
        rej(error);
      }
    });
  };

  const setPsapBoundariesOnMap = async psapArr => {
    try {
      if (psapArr.length > 0) {
        let newBounds = new window.google.maps.LatLngBounds();
        let newPolygons = [];
        psapArr.forEach((psap, i) => {
          let polygon = new window.google.maps.Polygon({
            map: gmap,
            paths: psap["psap_polygon_coordinates"],
            strokeColor: "#00FF00",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#00FD00",
            fillOpacity: 0.35,
            name: psap["psap_name"],
            zIndex: 4,
          });
          window.google.maps.event.addListener(polygon, "click", event => {
            infowindow.setContent(constructInfoWindow(psap, psapBoundaryPolygonInfoWindowMap, "PSAP Boundary Details"));
            infowindow.setPosition(event.latLng);
            infowindow.open(gmap);
          });
          newPolygons.push(polygon);
        });
        setPolygons(newPolygons);
        if (latestSubmit === "psap") {
          for (let j = 0; j < newPolygons.length; j++) {
            for (let i = 0; i < newPolygons[j].getPath().getLength(); i++) {
              newBounds.extend(newPolygons[j].getPath().getAt(i));
            }
          }
          gmap.fitBounds(newBounds);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const clearMarkers = async () => {
    return new Promise((res, rej) => {
      try {
        let i = 0;
        for (i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
        if (i === markers.length) {
          setMarkers([]);
          res("success");
        }
      } catch (error) {
        rej(error);
      }
    });
  };

  const setRfCellSites = async sitesArr => {
    try {
      if (Array.isArray(sitesArr) && sitesArr.length > 0) {
        let marker;
        let newMarkers = [];
        let newBounds = new window.google.maps.LatLngBounds();
        const rfSiteMarker = {
          path: "m38 30.0833c1.7489 0 3.1667 1.4178 3.1667 3.1667 0 1.7489-1.4178 3.1667-3.1667 3.1667-1.7489 0-3.1667-1.4178-3.1667-3.1667 0-1.7489 1.4178-3.1667 3.1667-3.1667zm2.5749 8.5322 2.9667 18.3845-11.0833 0 2.9668-18.3845 5.1498 0zm14.8418-5.3655c0 4.0617-1.3904 7.7986-3.7211 10.7609l-2.4901-1.9565c1.9069-2.4237 3.0445-5.4812 3.0445-8.8044 0-3.3232-1.1376-6.3807-3.0445-8.8043l2.4901-1.9565c2.3307 2.9622 3.7211 6.6991 3.7211 10.7608zm-31.1123 10.7609c-2.3307-2.9623-3.7211-6.6992-3.7211-10.7609 0-4.0617 1.3904-7.7986 3.7211-10.7609l2.4901 1.9566c-1.907 2.4236-3.0445 5.4811-3.0445 8.8043 0 3.3232 1.1375 6.3807 3.0445 8.8043l-2.4901 1.9566zm22.411-17.6087c1.4832 1.8851 2.3679 4.2631 2.3679 6.8478 0 2.5847-.8847 4.9628-2.3679 6.8478l-2.4901-1.9565c1.0594-1.3465 1.6914-3.0451 1.6914-4.8913 0-1.8462-.632-3.5448-1.6914-4.8913l2.4901-1.9565zm-17.4308 13.6956c-1.4832-1.8851-2.3679-4.2631-2.3679-6.8478 0-2.5847.8847-4.9628 2.3679-6.8478l2.4901 1.9565c-1.0594 1.3465-1.6914 3.0451-1.6914 4.8913 0 1.8462.632 3.5448 1.6914 4.8913l-2.4901 1.9565z",
          fillColor: "#ff0000",
          fillOpacity: 1,
          strokeWeight: 0.1,
          // rotation: 240,
          scale: 0.9,
          anchor: new window.google.maps.Point(1, 0),
        };
        sitesArr.map((site, index) => {
          let position = new window.google.maps.LatLng(site.lat, site.lng);
          marker = new window.google.maps.Marker({
            position: position,
            map: gmap,
            // icon: rfSiteMarker,
            title: `${site.name}`,
          });
          // window.google.maps.event.addListener(
          //   marker,
          //   "click",
          //   ((marker, i) => {
          //     return () => {
          //       infowindow.setContent(
          //         constructInfoWindow(site, rfCellSitesInfoWindowMap, "Site Details", { viewHandler: setShowModal, dataHandler: setModalData })
          //       );
          //       infowindow.open(gmap, marker);
          //     };
          //   })(marker, index)
          // );
          newMarkers.push(marker);
          if (latestSubmit === "rf") {
            newBounds.extend(position);
            gmap.fitBounds(newBounds);
            gmap.panToBounds(newBounds);
          }
        });
        setMarkers(newMarkers);
        // setBounds(newBounds);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const clearLiveTechniciansOnMap = async () => {
    return new Promise((res, rej) => {
      try {
        let i = 0;
        for (i = 0; i < mapLiveTechnicians.length; i++) {
          mapLiveTechnicians[i].setMap(null);
        }
        if (i === mapLiveTechnicians.length) {
          setMapLiveTechnicians([]);
          res("success");
        }
      } catch (error) {
        rej(error);
      }
    });
  };

  const setLiveTechniciansOnMap = async techArr => {
    try {
      if (Array.isArray(techArr) && techArr.length > 0) {
        let marker;
        let markersArray = [];
        techArr.forEach(tech => {
          const svgMarker = {
            path: "M-.1-61.525c2.825 0 5.125 2.3 5.125 5.125s-2.3 5.125-5.125 5.125-5.125-2.3-5.125-5.125S-2.925-61.525-.1-61.525zM6.35-49.95h-12.8c-3.55 0-6.4 2.85-6.4 6.4v15.65c0 1.225.975 2.25 2.25 2.25s2.25-.975 2.25-2.25v-14.375c0-.35.3-.65.65-.65.35 0 .65.3.65.65v38.8c0 1.925 1.425 3.5 3.2 3.5s3.2-1.575 3.2-3.5v-22.125c0-.35.3-.65.65-.65s.65.3.65.65v22.125c0 1.925 1.425 3.5 3.2 3.5s3.2-1.575 3.2-3.5V-42.275c0-.35.3-.65.65-.65.35 0 .65.3.65.65v14.4c0 1.225.975 2.25 2.25 2.25s2.25-.975 2.25-2.25v-15.675C12.75-47.1 9.825-49.95 6.35-49.95z",
            fillColor: "#000",
            fillOpacity: 1,
            strokeWeight: 0,
            // rotation: 180,
            scale: 0.75,
            anchor: new window.google.maps.Point(-1, 0),
          };
          marker = new window.google.maps.Marker({
            position: { lat: tech.latitude, lng: tech.longitude },
            map: gmap,
            icon: svgMarker,
            title: `${tech.tech_email}`,
          });
          markersArray.push(marker);
          // mapLiveTechnicians.filter(m => m["title"] === tech["tech_email"]).length === 0 &&
        });
        setMapLiveTechnicians(markersArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //other functions

  // const modifyRfSitesResponse = (array, key) => {
  //   let obj = {};
  //   array.forEach(item => {
  //     let { city, id_project, id_project_location, latitude, longitude, market, project_location_name, state, ...others } = item;
  //     obj[item[key]] = obj.hasOwnProperty(item[key])
  //       ? {
  //           ...obj[item[key]],
  //           sectors: [...obj[item[key]]["sectors"], others],
  //         }
  //       : {
  //           city,
  //           id_project,
  //           id_project_location,
  //           latitude,
  //           longitude,
  //           market,
  //           project_location_name,
  //           state,
  //           sectors: [others],
  //         };
  //   });
  //   return Object.values(obj);
  // };

  const getPolygonCoordinates = stringifiedInput => {
    let coordinates = JSON.parse(stringifiedInput);
    return coordinates.map(latlng => ({ lat: latlng[1], lng: latlng[0] }));
  };

  const getPolygonCenterCoordinates = polygon => {
    const findMin = arr => Math.min(...arr);
    const findMax = arr => Math.max(...arr);

    let latArr = polygon.map(latlng => latlng["lat"]);
    let lngArr = polygon.map(latlng => latlng["lng"]);

    let latlngMinMax = {
      lat: {
        min: findMin(latArr),
        max: findMax(latArr),
      },
      lng: {
        min: findMin(lngArr),
        max: findMin(lngArr),
      },
    };
    return {
      lat: latlngMinMax.lat.min + (latlngMinMax.lat.max - latlngMinMax.lat.min) / 2,
      lng: latlngMinMax.lng.min + (latlngMinMax.lng.max - latlngMinMax.lng.min) / 2,
    };
  };

  // const getUniqueMarkets = data =>
  //   [...new Set(data.map(el => el["nrcns_market"]))].map(el => ({
  //     nrcns_market: el,
  //   }));

  const initWebSocket = () => {
    webSocketRef.current = new WebSocket(wsTechsOnline);
    webSocketRef.current.onopen = () => {
      console.log("WEBSOCKET - CONNECTION ACTIVE");
      webSocketRef.current.send(JSON.stringify({ action: "listoftechsonline" })); //, data: { dashboard_user_info: { dashboard_user_email: "sandeep.d@dish.com" } }
    };
    webSocketRef.current.onmessage = e => {
      let res = JSON.parse(e.data);
      liveTechnicians.length === 0
        ? setLiveTechnicians(res)
        : res.forEach(newItem => {
            let index = liveTechnicians.findIndex(oldItem => oldItem.tech_email === newItem.tech_email);
            if (index === -1) {
              setLiveTechnicians(prev => [...prev, newItem]);
            } else {
              let newData = [...liveTechnicians];
              newData[index].tech_last_shown_time = newItem.tech_last_shown_time;
              newData[index].latitude = newItem.latitude;
              newData[index].longitude = newItem.longitude;
              setLiveTechnicians(newData);
            }
          });
    };
    webSocketRef.current.onclose = () => console.log("WEBSOCKET - CONNECTION CLOSED");
    return webSocketRef.current;
  };

  // const generateMockData = (count = 50) => {
  //   let res = [];
  //   let randomCount = Math.floor(Math.random() * count);
  //   for (let i = 0; i < randomCount; i++) {
  //     res.push({
  //       connection_id: i + 1,
  //       tech_last_shown_time: new Date().setSeconds(-(i * 20)),
  //       latitude: 39.54593 + i / 1000,
  //       longitude: -104.856365 + i / 1000,
  //       connection_type: "",
  //       tech_email: "",
  //       tech_authorised: "",
  //       tech_dial_number: "",
  //       tech_cell_ids: "",
  //       tech_site_name: "",
  //       tech_manger_id: "",
  //       tech_region: "",
  //       tech_login_time: "",
  //       tech_last_action_time: "",
  //     });
  //   }
  //   return res;
  // };

  const checkInactiveSessions = (maxMinutes = 10) => {
    let currentTime = new Date().getTime();
    let maxMilliSeconds = maxMinutes * 60 * 1000;
    setLiveTechnicians(prev => prev.filter(item => currentTime - getTimeString(item.tech_last_shown_time) < maxMilliSeconds));
  };

  const getTimeString = dt => {
    let ts;
    if (dt.includes("+")) {
      ts = dt.split("+")[0] + "Z";
    } else {
      ts = dt;
    }
    return new Date(ts).getTime();
  };

  //useEffects
  useEffect(() => {
    init();
    let ws = initWebSocket();
    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {}, [showModal, modalData]);

  useEffect(() => {
    if (isMapLoaded && psapBoundaries.length === 0 && rfSites.length === 0) {
      setDefaultMarker();
    }
  }, [isMapLoaded, psapBoundaries, rfSites]);

  useEffect(() => {
    rfSites.length > 0 && clearMarkers().then(clearPolygons()).then(setRfCellSites(rfSites)).then(setPsapBoundariesOnMap(psapBoundaries));
  }, [rfSites]);

  useEffect(() => {
    clearPolygons().then(setPsapBoundariesOnMap(psapBoundaries));
  }, [psapBoundaries]);

  useEffect(() => {
    if (liveTrackTechnicians) {
      clearLiveTechniciansOnMap().then(() => {
        if (liveTechnicians.length > 0) {
          setLiveTechniciansOnMap(liveTechnicians);
        }
      });
    }
  }, [liveTechnicians]);

  useEffect(() => {
    mapLiveTechnicians.length > 0 && console.log("Map", mapLiveTechnicians);
  }, [mapLiveTechnicians]);

  // useEffect(() => {
  //   let handle;
  //   if (liveTrackTechnicians === false) {
  //     return;
  //   } else {
  //     handle = setInterval(() => getTechsOnline(25), Math.floor(Math.random() * 25000));
  //   }
  //   return () => {
  //     clearInterval(handle);
  //   };
  // }, [liveTrackTechnicians]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactiveSessions(5);
    }, TECHS_ONLINE_REFRESH_DURATION);
    return () => clearInterval(interval);
  }, []);

  //render

  return (
    <div>
      <div className="CustomPageHeader">
        <label>PSAP Boundary Map</label>
      </div>
      <Grid fluid style={{ margin: 20 }}>
        <Row style={{ marginBottom: 12, display: "flex", alignItems: "center" }}>
          <Col xs={2} style={{ display: "flex", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>PSAP</Typography>
          </Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>State</Typography>
          </Col>
          <Col xs={4}>
            <InputPicker
              value={psapStates.active["psap_state"]}
              defaultValue={psapStates.active["psap_state"]}
              disabled={psapStates.isLoading}
              data={psapStates.data}
              labelKey={"psap_state_name"}
              valueKey={"psap_state"}
              style={{ width: "100%" }}
              onSelect={(val, item) => onSelectPsapState(item)}
              onClean={() => setPsapStates(prev => ({ ...prev, active: {} }))}
            />
          </Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>City</Typography>
          </Col>
          <Col xs={4}>
            <InputPicker
              value={psapCities.active["psap_city"]}
              defaultValue={psapCities.active["psap_city"]}
              disabled={psapCities.isLoading || psapCities.data.length === 0}
              data={psapCities.data}
              labelKey={"psap_city"}
              valueKey={"psap_city"}
              style={{ width: "100%" }}
              onSelect={(val, item) => onSelectPsapCity(item)}
              onClean={() => setPsapCities(prev => ({ ...prev, active: {} }))}
            />
          </Col>
          <Col xs={2}></Col>
          <Col xs={4}></Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Button className={classes.button} onClick={onSubmitPsap} disabled={Object.keys(psapCities.active).length === 0} loading={isBtnLoading.psapBtn}>
              Submit
            </Button>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col xs={2} style={{ display: "flex", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>Dish Site</Typography>
          </Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>Region</Typography>
          </Col>
          <Col xs={4}>
            {initialRoleFetchLoading && <RLoader backdrop vertical style={{ zIndex: 20000 }} />}
            <InputPicker
              value={rfRegions.active.name ?? "Select Region"}
              defaultValue={rfRegions.active.name ?? "Select Region"}
              disabled={rfRegions.isLoading}
              data={rfRegions.data.length === 0 ? [rfRegions.active] : rfRegions.data}
              labelKey={"name"}
              valueKey={"name"}
              style={{ width: "100%" }}
              onSelect={(val, item) => onSelectRfRegion(item)}
              onClean={() => setRfRegions(prev => ({ ...prev, active: {} }))}
            />
          </Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>Market</Typography>
          </Col>
          <Col xs={4}>
            {initialRoleFetchLoading && <RLoader backdrop vertical style={{ zIndex: 20000 }} />}
            <InputPicker
              value={rfMarkets.active.name ?? "Select Market"}
              defaultValue={rfMarkets.active.name ?? "Select Market"}
              disabled={rfMarkets.isLoading}
              data={rfMarkets.data.length === 0 ? [rfMarkets.active] : rfMarkets.data}
              labelKey={"name"}
              valueKey={"name"}
              style={{ width: "100%" }}
              onSelect={(val, item) => onSelectRfMarket(item)}
              onClean={() => setRfMarkets(prev => ({ ...prev, active: {} }))}
            />
          </Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Typography style={{ fontFamily: "Lato", color: "#2E384D", fontWeight: 700, fontSize: 14, width: "max-content" }}>AOI</Typography>
          </Col>
          <Col xs={4}>
            <InputPicker
              value={rfCities.active.name ?? "Select AOI"}
              defaultValue={rfCities.active.name ?? "Select AOI"}
              disabled={rfCities.isLoading || rfCities.data.length === 0}
              data={rfCities.data}
              labelKey={"name"}
              valueKey={"name"}
              style={{ width: "100%" }}
              onSelect={(val, item) => onSelectRfCity(item)}
              onClean={() => setRfCities(prev => ({ ...prev, active: {} }))}
            />
          </Col>
          <Col xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingTop: 4, paddingRight: 10 }}>
            <Button
              className={classes.button}
              style={{ visibility: "hidden" }}
              onClick={onSubmitRf}
              disabled={Object.keys(rfCities.active).length === 0}
              loading={isBtnLoading.rfSiteBtn}>
              Submit
            </Button>
          </Col>
        </Row>
      </Grid>
      <div id="map"></div>
      <SiteDetailsModal
        show={showModal}
        data={modalData}
        onHide={() => {
          setShowModal(false);
          setModalData({});
        }}
      />
    </div>
  );
};

export default CellMapView;
