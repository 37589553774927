import React from "react";
import Logo from "../assets/icons/logo.png";
import IconLabel from "./LabelWithIcon";
import ROUTES from "../config/routes";

const Sidebar = () => {
  return (
    <>
      <div className="SidebarHeader">
        <img src={Logo} alt="" />
        <label>Cell Tech</label>
      </div>
      <div className="SidebarHeaderDivider" />
      <br />
      {ROUTES.map((route, index) => {
        return <IconLabel data={route} key={`route-${index}`} />;
      })}
    </>
  );
};

export default Sidebar;
