import React, { useEffect, useState } from "react";
import {
  IconButton,
  Icon,
  Table,
  Input,
  InputGroup,
  Button,
  Modal,
  Notification,
  InputPicker,
  Form,
  ControlLabel,
  FormGroup,
  FormControl,
  ButtonToolbar,
  Schema,
  Checkbox,
  Loader,
  RadioGroup,
  Radio
} from "rsuite";
import EditIcon from "@material-ui/icons/Edit";
import { TECHNICIANS_LIST_TABLE } from "../config/TableConfig";
import { get, post } from "../webservices/apiservice";
import ENDPOINTS from "../webservices/endpoints";
import { commonStyles } from "../config/theme";
import CustomToggle from "../components/toggle";
import { CSVLink } from 'react-csv';
import download from '../assets/icons/download.png';
import { saveAs } from 'file-saver';



const { Column, HeaderCell, Cell, Pagination } = Table;

const TABLE_ROWS_SIZES = [
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];
let CSV_HEADER = [];
let csvReport = {};
let selectedTechnicianTestReportData = "";

const TechniciansList = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableSortColumn, setTableSortColumn] = useState("Id");
  const [columnSortType, setColumnSortType] = useState("asc");
  const [techniciansData, setTechniciansData] = useState([]);
  const [pageDataItems, setPageDataItems] = useState([]);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(TABLE_ROWS_SIZES[1]["value"]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [updateTechnician, setUpdateTechnician] = useState(false);
  const [rowDataID, setRowDataID] = useState();
  const [emailReadonly, setEmailReadonly] = useState(false);
  const [formValue, setFormValue] = useState({
    tech_email: "",
    tech_authorised: "true",
    tech_dial_number: "",
    tech_cell_ids: "",
    tech_site_name: "",
  });
  const [isAuthorised, setIsAuthorised] = useState(true);
  const [formReadOnly, setFormReadOnly] = useState(false);
  const [modalHeading, setModalHeading] = useState("Add Technician");
  const [isEdit, setIsEdit] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [isSitesLoading, setSitesLoading] = useState(false);
  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
  const [reportDownLoadingFormat, setReportDownLoadingFormat] = useState("csv");

  const formRef = React.useRef();

  const data = [];
  const getTechnicianListUrl = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.GET_TECHNICIANS;
  const postAuthoriseTechnicianUrl = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.POST_AUTHORISE_TECHNICIAN;
  const postUpdateTechnicianStatusUrl = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.POST_UPDATE_TECHNICIAN_STATUS;
  const getSearchSitesUrl = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.GET_RF_SEARCH_SITES;
  const getSiteCiqUrl = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.GET_RF_SITE_CIQ;

  const globalClasses = commonStyles();

  const { StringType, NumberType } = Schema.Types;
  const model = Schema.Model({
    tech_dial_number: StringType().isRequired("Dial Number is Required."),
    tech_email: StringType()
      .addRule((value, data) => {
        if (/^[a-zA-Z0-9](\.?[a-zA-Z0-9]){4,}@dish\.com$/.test(value) !== true) {
          return false;
        }
        return true;
      }, "Please enter the valid Dish email address")
      .isRequired("Email ID is Required."),
  });

  let submitButton = (
    <Button className={globalClasses.buttonPrimary} type="submit" onClick={() => handleSubmit("save")}>
      Save
    </Button>
  );
  if (formReadOnly) {
    submitButton = (
      <Button appearance="primary" type="submit" className={globalClasses.buttonPrimary} onClick={() => handleSubmit("edit")}>
        Edit
      </Button>
    );
  }

  const getRemoteData = async () => {
    try {
      let response = await get(getTechnicianListUrl);
      let items = response["items"];
      items.map((item, index) => {
        item["id"] = index + 1;
        // item["tech_cell_ids"] = item["tech_cell_ids"].length > 0 ? item["tech_cell_ids"].toString() : `-`;
        // item["tech_site_name"] = item["tech_site_name"] !== "" ? item["tech_site_name"].toString() : `-`;
        // item["latlong"] = `${item["sitelocation"]["lat"]} / ${item["sitelocation"]["long"]}`;
        return item;
      });
      setIsPageLoading(false);
      setTechniciansData(items);
      let pageItems = getPageDataItems(items);
      setPageDataItems(getSortedData(pageItems));
    } catch (error) {
      console.error(error);
    }
  };

  const getSites = async input => {
    try {
      setSitesLoading(true);
      let queryString = `${getSearchSitesUrl}?keyword=${input}`;
      let response = await get(queryString);
      setSitesData(response.items.map(item => ({ label: item["nrcns_site_name"], value: item["nrcns_site_name"] })));
      setSitesLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getSitesCiq = async input => {
    try {
      let queryString = `${getSiteCiqUrl}?site=${input}`;
      let response = await get(queryString);
      let cellIdsString = response.items
        .filter(item => item["nrcns_cell_id"] !== "")
        .map(el => el["nrcns_cell_id"])
        .toString();
      setFormValue(prev => ({ ...prev, ["tech_cell_ids"]: cellIdsString }));
    } catch (err) {
      console.error(err);
    }
  };

  const getTechnicianTestReportData = async (email) => {
    try {
      let response = await get("https://r7hlyzm0v1.execute-api.us-west-2.amazonaws.com/v1/celltech/dashboard/testresults/download?email=" + email);

      if (response.status === 200) {
        selectedTechnicianTestReportData = response.items;
        let data = [];

        for (let name in selectedTechnicianTestReportData[0]) {

          CSV_HEADER.push(name);
        }
        for (let index in selectedTechnicianTestReportData) {
          data.push(Object.values(selectedTechnicianTestReportData[index]));
        }
        csvReport.filename = email.replace(/@[^@]+$/, '') + "_field_test_report.csv";
        csvReport.headers = CSV_HEADER;
        csvReport.data = data;
        setShowDownloadReportModal(true)
      } else {
        alert("Error Please try again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAction = (rowData, action) => {
    if (action === "Download") {
      getTechnicianTestReportData(rowData.tech_email);
      setFormValue(rowData);
    } else {
      setIsEdit(false);
      setShowPopUp(true);
      setFormReadOnly(true);
      setEmailReadonly(true);
      setFormValue(rowData);
      // setRowDataID(rowData.id);
      setIsAuthorised(rowData["tech_authorised"]);
      setModalHeading("Technician Details");
    }
  };

  const getSortedData = data => {
    let sortColumn = tableSortColumn;
    let sortType = columnSortType;

    if (sortColumn && sortType) {
      data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
      return data;
    }

    return data;
  };

  const handleColumnSort = (column, sortType) => {
    setIsTableLoading(true);

    setTimeout(() => {
      setTableSortColumn(column);
      setColumnSortType(sortType);
      setIsTableLoading(false);
    }, 500);
  };

  const handlePageChange = page => {
    setActivePageNumber(page);
  };

  const handleLengthChange = numOfRows => {
    setActivePageNumber(1);
    setItemsPerPage(numOfRows);
  };

  const getPageDataItems = sourceData => {
    let items = sourceData === undefined ? techniciansData : sourceData;
    let newData = items.filter((v, i) => {
      const start = itemsPerPage * (activePageNumber - 1);
      const end = start + itemsPerPage;
      return i >= start && i < end;
    });
    return newData;
  };

  function openAddNewTechnicianPopup(event) {
    event.target.blur();
    // setIsEdit(false);
    setModalHeading("Add Technician");
    setShowPopUp(true);
    setFormValue({});
    setEmailReadonly(false);
    setFormReadOnly(false);
    setUpdateTechnician(false);
  }

  function closeAddNewTechnicianPopup() {
    setShowPopUp(false);
    setIsEdit(false);
  }

  function handleonChange(val, checked) {
    setIsAuthorised(checked);
  }

  function onChangeFormValue(formValue) {
    setFormValue(formValue);
  }

  function validateEmail(email) {
    let regexEmail = /^[a-zA-Z0-9](\.?[a-zA-Z0-9]){4,}@dish\.com$/;
    if (regexEmail.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function checkForNonEmptyValue(key) {
    if (formValue[key] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  const addTechnician = async () => {
    let payload = {
      tech_authorised: isAuthorised,
      tech_email: formValue.tech_email,
      tech_dial_number: formValue.tech_dial_number,
      tech_added_by: "manjunath.hegde@dish.com",
      tech_site_name: formValue.tech_site_name,
      tech_cell_ids: formValue.tech_cell_ids,
    };
    try {
      let response = await post(postAuthoriseTechnicianUrl, payload);
      if (response.status === 200) {
        setShowPopUp(false);
        Notification["success"]({
          title: `Success`,
          description: `Technician is added successfully.`,
        });
        getRemoteData();
      } else {
        alert("Error Please try again");
      }
    } catch (err) {
      console.error(err);
      Notification["error"]({
        title: `Error`,
        description: `An error occured.`,
      });
    }
  };

  const upDateTechnician = async () => {
    let payload = { ...formValue, tech_authorised: isAuthorised };
    try {
      await post(postAuthoriseTechnicianUrl, payload);
      setShowPopUp(false);
    } catch (err) {
      console.error(err);
      alert("Error Please try again");
    }
  };

  const handleSubmit = evtName => {
    if (evtName === "save") {
      if (validateEmail(formValue.tech_email) && checkForNonEmptyValue("tech_dial_number")) {
        if (updateTechnician) {
          upDateTechnician();
        } else {
          addTechnician();
        }
      }
    } else {
      setFormReadOnly(false);
      setModalHeading("Edit Technician Info");
      setUpdateTechnician(true);
    }
  };

  const handleToggle = async (rowData, checked) => {
    let payload = {
      tech_email: rowData.tech_email,
      tech_authorised: checked,
    };
    try {
      await post(postUpdateTechnicianStatusUrl, payload);
      Notification["success"]({
        title: `Success`,
        description: checked ? `Technician is successfully authorised.` : `Technician is successfully Un-authorised`,
      });
    } catch (error) {
      Notification["error"]({
        title: `Failure`,
        description: checked ? `Technician couldn't be authorised. Try again` : `Technician couldn't be Deauthorised. Try again`,
      });
    }
  };

  const handleClose = () => {
    setShowDownloadReportModal(false);
    setReportDownLoadingFormat("csv");
  }

  function handleReportingFormatChange(format) {
    setReportDownLoadingFormat(format)
  }

  function handleDownloadReport() {
    let data = JSON.stringify(selectedTechnicianTestReportData);
      let fileName = formValue.tech_email.replace(/@[^@]+$/, '') + '_field_test_report.json';
      var fileToSave = new Blob([data], {
        type: 'application/json'
      });
      saveAs(fileToSave, fileName);
      setReportDownLoadingFormat("csv");
      setShowDownloadReportModal(false);
  }


  const DownloadModal = () => {

    let downLoadButton = <CSVLink className="saveButton" {...csvReport}><Button appearance="default" appearance="primary" onClick={handleClose} className="saveButton">Download</Button>
    </CSVLink>
    if (reportDownLoadingFormat === "json") {
      downLoadButton = <Button appearance="default" appearance="primary" className="saveButton" onClick={handleDownloadReport}>Download</Button>
    }

    return (
      <Modal size='sm' show={showDownloadReportModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title ></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <div className="downloadImage">
            <img src={download} alt="" />
          </div>
          <div className="modalsubHeadingBlock">
            <p className="DownloadModalTitle">Download the field test report</p>
            <p className="modalSubHeading">Select the file format</p>
            <RadioGroup name="radioList" inline onChange={handleReportingFormatChange} value={reportDownLoadingFormat}>
              <Radio name="test" className="radioOption" value="csv">CSV</Radio>
              <Radio name="test" className="radioOption" value="json">JSON</Radio>
            </RadioGroup>
          </div>
        </Modal.Body >
        <hr className="divider" />
        <Modal.Footer>
          <Button appearance="default" className="cancelButton" onClick={handleClose}>Cancel</Button>
          {downLoadButton}
        </Modal.Footer>
      </Modal >
    )
  };

  const enableEdit = e => {
    e.target.blur();
    setIsEdit(true);
    setModalHeading("Edit Technician");
    setFormReadOnly(false);
    setEmailReadonly(false);
  };

  useEffect(() => {
    let pageItems = getPageDataItems();
    setPageDataItems(getSortedData(pageItems));
  }, [activePageNumber, itemsPerPage, tableSortColumn, columnSortType]);

  useEffect(() => {
    getRemoteData();
  }, []);

  useEffect(() => { }, [techniciansData]);
  useEffect(() => { }, [pageDataItems]);
  useEffect(() => { }, [sitesData]);
  useEffect(() => { }, [formValue]);
  useEffect(() => { }, [isSitesLoading]);
  useEffect(() => { }, [isTableLoading]);
  useEffect(() => { }, [isEdit]);

  return (
    <div className="TableView">
      <div className="CustomPageHeader">
        <label>Technicians</label>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 24px 0px 24px" }}>
        <InputGroup inside style={{ maxWidth: "50vw", marginRight: 15 }}>
          <Input className={globalClasses.searchBarInput} placeholder="Search technician email" />
          <InputGroup.Addon className="SearchIcon">
            <Icon className={globalClasses.searchBarIcon} icon="search" />
          </InputGroup.Addon>
        </InputGroup>
        <Button
          className={globalClasses.buttonPrimary}
          onClick={e => {
            setIsEdit(true);
            openAddNewTechnicianPopup(e);
          }}>
          Add New
        </Button>
      </div>
      <div style={{ marginLeft: "24px", marginRight: "24px", marginTop: "16px" }}>
        <>
          <Table
            height={420}
            data={pageDataItems}
            sortColumn={tableSortColumn}
            sortType={columnSortType}
            onSortColumn={handleColumnSort}
            loading={isPageLoading}>
            {TECHNICIANS_LIST_TABLE.map((item, index) => {
              return (
                <Column width={item["width"]} align={item["align"]} sortable={item["sortable"]}>
                  <HeaderCell>{item["header"]}</HeaderCell>
                  {item["header"] === "Action" ? (
                    <Cell>
                      {
                        rowData => {
                          function handleOnClickView() {
                            handleAction(rowData, "View");
                          }

                          function handleOnClickDownload() {
                            handleAction(rowData, "Download");
                          }
                          return (
                            <span>
                              <IconButton appearance="subtle" icon={<Icon icon="eye" />} onClick={handleOnClickView} />
                              <IconButton appearance="subtle" icon={<Icon icon="download" />} onClick={handleOnClickDownload} />
                            </span>
                          )
                        }
                      }
                    </Cell>
                  ) : item["dataKey"] === "tech_authorised" ? (
                    <Cell className="TableViewCustomCellValue" dataKey={item["dataKey"]}>
                      {rowData => <CustomToggle checked={rowData.tech_authorised} otherData={rowData} onToggle={handleToggle} />}
                    </Cell>
                  ) : (
                    <Cell className="TableViewCustomCellValue" dataKey={item["dataKey"]} />
                  )}
                </Column>
              );
            })}
          </Table>
          {showDownloadReportModal ? <DownloadModal /> : null}
          <Pagination
            lengthMenu={TABLE_ROWS_SIZES}
            activePage={activePageNumber}
            displayLength={itemsPerPage}
            total={techniciansData.length}
            onChangePage={handlePageChange}
            onChangeLength={handleLengthChange}
          />
        </>
      </div>
      <Modal show={showPopUp} size="md" onHide={closeAddNewTechnicianPopup}>
        <Modal.Header>
          <Modal.Title className={globalClasses.label} style={{ color: "#3489ec", marginLeft: 5, display: "flex", alignItems: "end" }}>
            {modalHeading}
            {isEdit === false && modalHeading === "Technician Details" ? (
              <EditIcon style={{ fontSize: 18, marginLeft: 10, cursor: "pointer" }} onClick={enableEdit} />
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: 5 }}>
          <Form fluid model={model} ref={formRef} formValue={formValue} onChange={onChangeFormValue}>
            <FormGroup>
              <ControlLabel className={globalClasses.label}>Email</ControlLabel>
              <FormControl
                name="tech_email"
                autofill="false"
                className={globalClasses.input}
                readOnly={emailReadonly}
                placeholder="Enter technician email here (user@dish.com)"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel className={globalClasses.label}>Dial Number</ControlLabel>
              <FormControl
                name="tech_dial_number"
                className={globalClasses.input}
                readOnly={formReadOnly}
                placeholder="Enter dial number to test (911 is default). It could be 922, 522"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel className={globalClasses.label}>NR Cell IDs</ControlLabel>
              <FormControl name="tech_cell_ids" className={globalClasses.input} readOnly={true} placeholder="Comma Seperated" />
              <InputGroup style={{ width: "100%", marginLeft: 5, marginTop: 10, marginRight: 0 }}>
                <InputGroup.Addon className="SearchIcon" style={{ backgroundColor: "#fff" }}>
                  <Icon className={globalClasses.searchBarIcon} icon="search" />
                </InputGroup.Addon>
                <InputPicker
                  data={sitesData}
                  defaultValue={formValue["tech_site_name"]}
                  value={formValue["tech_site_name"]}
                  disabled={!isEdit}
                  style={{ width: "100%", border: 0 }}
                  labelKey="label"
                  valueKey="value"
                  onSearch={getSites}
                  onSelect={val => {
                    setFormValue(prev => ({ ...prev, ["tech_site_name"]: val }));
                    getSitesCiq(val);
                  }}
                  onClean={() => {
                    setFormValue(prev => ({ ...prev, ["tech_site_name"]: "" }));
                  }}
                  placeholder={
                    isEdit
                      ? `Search Project Name or Site Name to fill Nr Cell IDs above`
                      : formValue["tech_site_name"] === ""
                        ? `Search Project Name or Site Name to fill Nr Cell IDs above`
                        : formValue["tech_site_name"]
                  }
                  menuStyle={{ width: "inherit", marginTop: 2 }}
                  renderMenu={menu => {
                    if (isSitesLoading) {
                      return (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", padding: 4 }}>
                          <Loader style={{ marginLeft: 2 }} />
                          <label style={{ marginLeft: 4, color: "#999", textAlign: "center" }}> Loading...</label>
                        </div>
                      );
                    }
                    return menu;
                  }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Checkbox
                className="checkBoxText"
                disabled={formReadOnly}
                id="authoriseCheckBox"
                name="authorise"
                value="authorise"
                checked={isAuthorised}
                onChange={handleonChange}>
                Authorise
              </Checkbox>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: 20 }}>
          {formReadOnly && emailReadonly ? (
            <ButtonToolbar style={{ display: "flex", justifyContent: "space-between" }}>
              <Button className={globalClasses.buttonSecondary} onClick={closeAddNewTechnicianPopup}>
                Close
              </Button>
              <Button className={globalClasses.buttonPrimary} onClick={enableEdit}>
                Edit
              </Button>
            </ButtonToolbar>
          ) : (
            <ButtonToolbar style={{ display: "flex", justifyContent: "space-between" }}>
              <Button className={globalClasses.buttonSecondary} onClick={closeAddNewTechnicianPopup}>
                Cancel
              </Button>
              {submitButton}
            </ButtonToolbar>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TechniciansList;
