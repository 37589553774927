let HAS_LOGGED_IN = false;

const updateRequestHeader = jwtToken => {
  ENDPOINTS.WEB_HEADERS["Authorization"] = jwtToken;
  HAS_LOGGED_IN = true;
};

const getRequestHeader = () => {
  return ENDPOINTS.WEB_HEADERS;
};

const hasLoggedIn = () => {
  if (sessionStorage.getItem("jwt") === null) {
    return false;
  } else {
    ENDPOINTS.WEB_HEADERS["Authorization"] = sessionStorage.getItem("jwt");
    return true;
  }
};

const logOut = () => {
  HAS_LOGGED_IN = false;
};

const REST_ENDPOINTS = {
  MAPS: {
    POST_PSAP_GEOJSON: "/celltech/dashboard/psap/boundary",
    GET_PSAP_STATES: "/celltech/dashboard/psap/states",
    GET_PSAP_CITIES: "/celltech/dashboard/psap/cities",
    GET_PSAP_BOUNDARY: "/celltech/dashboard/psap/boundary",
    GET_RF_REGIONS: "/celltech/dashboard/rfmanagement/getregions",
    GET_RF_MARKETS: "/celltech/dashboard/rfmanagement/getmarkets",
    GET_RF_CITIES: "/celltech/dashboard/rfmanagement/getcities",
    GET_RF_SITES: "/celltech/dashboard/rfmanagement/getsites",
    GET_RF_SITE_CIQ: "/celltech/dashboard/rfmanagement/getsiteciq",
    GET_TECHS_ONLINE: "/celltech/dashboard/listoftechsonline",

    WS_TECHS_ONLINE: "wss://celltech-status-nonprod-oss.dish.com",
    ROLE_MANAGEMENT: "/celltech/dashboard/rolemanagement/user",
    GET_AOIS: "/celltech/dashboard/rfmanagement/getaois",
  },
  TECHNICIANS: {
    GET_TECHNICIANS: "/celltech/dashboard/technician/getlist",
    POST_AUTHORISE_TECHNICIAN: "/celltech/dashboard/technician/authorise",
    POST_BULK_UPLOAD_TECHNICIANS: "/celltech/dashboard/technician/bulkupload",
    POST_UPDATE_TECHNICIAN_STATUS: "/celltech/dashboard/technician/updatestatus",
    GET_RF_SEARCH_SITES: "/celltech/dashboard/rfmanagement/searchsite",
    GET_RF_SITE_CIQ: "/celltech/dashboard/rfmanagement/getsiteciq",
    GET_TECHNICIAN_SEARCH: "/celltech/dashboard/technician/search",
  },
  TEST_REPORTS: {
    GET_REPORTS: "/celltech/dashboard/testresults/getlist",
  },
};

const ENDPOINTS = {
  DOMAIN: "https://r7hlyzm0v1.execute-api.us-west-2.amazonaws.com/v1",
  CLOUND_FRONT_URL: "",
  LOGIN: "",
  S3_UPLOAD_PATH: "",
  WEB_HEADERS: {
    "Content-Type": "application/json",
    "x-api-key": "senT2zlWbu9K8rzA8FHGA22c5SIsFboe47OimrdC",
  },
  ...REST_ENDPOINTS,
  updateRequestHeader,
  getRequestHeader,
  hasLoggedIn,
  logOut,
};

export default ENDPOINTS;
